import { Link } from "gatsby"
import React from "react"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
const Pricing = ({ data }) => {
  return (
    <section className="py-5">
      <Container className="py-5">
        <Row className="text-center mb-5">
          <Col lg="7" className="mx-auto">
            <p className="h6 text-uppercase text-primary">{data.subtitle}</p>
            <h2 className="mb-3">{data.title}</h2>
            <p className="text-muted mb-4">{data.content}</p>
          </Col>
        </Row>
        <Row className="align-items-center">
          {data.items.map((item) => (
            <Col lg="4" className="p-lg-0 mb-4 mb-lg-0" key={item.name}>
              <Card
                className={
                  item.featured ? "text-white pricing-featured" : "shadow"
                }
              >
                <CardBody className="text-center py-5 p-lg-5">
                  <p className="h5 mb-2 text-uppercase">{item.name}</p>
                  <p
                    className={`text-small ${
                      !item.featured ? "text-gray" : ""
                    }`}
                  >
                    {item.subtitle}
                  </p>
                  <h3 className="table-price mb-4">
                    <sup>$</sup>
                    {item.price}
                  </h3>
                  <ul className="list-unstyled mb-4">
                    {item.attributes.map((attribute) => (
                      <li
                        key={attribute}
                        className={`mb-1 ${
                          item.featured ? "font-weight-normal" : "text-muted"
                        }`}
                      >
                        {attribute}
                      </li>
                    ))}
                  </ul>
                  <Button
                    tag={Link}
                    to="#"
                    color={item.featured ? "light" : "primary"}
                    className={item.featured ? "bg-white text-primary" : ""}
                  >
                    Subscribe
                  </Button>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Pricing
