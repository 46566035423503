import React from "react"

import Layout from "../components/Layout"

import Partners from "../components/Partners"
import Services from "../components/Services"
import Hero from "../components/Hero"
import IntroText from "../components/IntroText"
import Marketing from "../components/Marketing"
import WorkProcess from "../components/WorkProcess"
import Blocks from "../components/Blocks"
import Pricing from "../components/Pricing"
import Newsletter from "../components/Newsletter"
import Testimonials from "../components/Testimonials"
import SEO from "../components/SEO"

import data from "../content/index.json"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Homepage" />
      <Hero home data={data.hero} />
      <Partners />
      <IntroText data={data.intro} />
      <Blocks data={data.blocks} />
      <Services data={data.services} />
      <Marketing data={data.marketing} />
      <WorkProcess data={data.process} />
      <Pricing data={data.pricing} />
      <Newsletter className="py-5 bg-light" />
      <Testimonials />
    </Layout>
  )
}

export default IndexPage
