import React from "react"
import { Col, Container, Row } from "reactstrap"
const IntroText = ({ data }) => {
  return (
    <section className="py-5">
      <Container className="py-5">
        <Row>
          <Col lg="6">
            <h2>{data.title}</h2>
          </Col>
          <Col lg="6">
            <p className="text-muted">{data.content}</p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default IntroText
