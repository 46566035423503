import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Icon from "../components/Icon"
const Services = ({ data }) => {
  return (
    <section className="pb-5">
      <Container className="pb-5">
        <Card className="shadow text-center map-bg">
          <CardBody className="p-lg-5">
            <div className="p-4">
              <Row lg="7" className="mb-5">
                <Col className="mx-auto">
                  <p className="h6 text-primary text-uppercase">
                    {data.subtitle}
                  </p>
                  <h2>{data.title}</h2>
                  <p className="text-muted">{data.content}</p>
                </Col>
              </Row>
              <Row>
                {data.items.map((item) => (
                  <Col key={item.title} lg="4" className="mb-3 mb-lg-0">
                    <Icon
                      icon={item.icon}
                      className="text-primary mb-4 svg-icon-big svg-icon-light"
                    />
                    <h5>{item.title}</h5>
                    <p className="text-muted mb-0">{item.content}</p>
                  </Col>
                ))}
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </section>
  )
}

export default Services
