import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Button, Col, Container, Row } from "reactstrap"
const Marketing = ({ data }) => {
  const images = useStaticQuery(graphql`
    query {
      marketing1: file(relativePath: { eq: "demo-img-6.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      marketing2: file(relativePath: { eq: "demo-img-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <section className="pb-5">
      <Container className="pb-5">
        <Row>
          {data.map((column, index) => (
            <Col
              key={index}
              lg="5"
              className={`mx-auto d-flex flex-column ${
                index === 0 ? "mb-5 mb-lg-0" : ""
              }`}
            >
              <div className="numeric-block order-2 order-lg-1 mb-lg-5">
                <div className="numeric-block-count">{index + 1}</div>
                <p className="text-muted text-uppercase mb-2">
                  {column.subtitle}
                </p>
                <h3>{column.title}</h3>
                <p className="text-muted py-3">{column.content}</p>
                {column.button && (
                  <Button tag={Link} to={column.button.link} color="primary">
                    {column.button.label}
                  </Button>
                )}
              </div>
              <Img
                fluid={images["marketing" + (index + 1)].childImageSharp.fluid}
                alt=""
                className={`flex-grow-0 img-fluid shadow rounded mb-5 ${
                  index === 0 ? "order-1 order-lg-2  mb-lg-0" : ""
                }`}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Marketing
