import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Icon from "./Icon"
const WorkProcess = ({ data }) => {
  return (
    <section className="py-5 bg-primary map-bg text-white">
      <Container className="text-center py-5">
        <Row className="mb-4">
          <Col lg="7" className="mx-auto">
            <p className="h6 text-uppercase">{data.subtitle}</p>
            <h2>{data.title}</h2>
            <p>{data.content}</p>
          </Col>
        </Row>
        <Row>
          {data.items.map((item) => (
            <Col lg="4" className="mx-auto" key={item.name}>
              <Card className="border-0 card-hover-bg">
                <CardBody className="p-lg-5">
                  <Icon
                    icon={item.icon}
                    className="svg-icon-big svg-icon-light mb-4"
                  />
                  <h4>{item.name}</h4>
                  <p className="text-small mb-0">{item.content}</p>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default WorkProcess
