import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import SwiperCore, { Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import Icon from "./Icon"
import "swiper/swiper.scss"
require("swiper/components/pagination/pagination.scss")

SwiperCore.use([Pagination, Autoplay])
const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      client1: file(relativePath: { eq: "client-1.jpg" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      client2: file(relativePath: { eq: "client-2.jpg" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      client3: file(relativePath: { eq: "client-3.jpg" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const slides = [
    {
      name: "John Charlez",
      type: "Iso client",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      img: data.client1.childImageSharp.fixed,
    },
    {
      name: "Samantha Lewis",
      type: "Iso client",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      img: data.client2.childImageSharp.fixed,
    },
    {
      name: "Carrie Steiniger",
      type: "Iso client",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      img: data.client3.childImageSharp.fixed,
    },
  ]

  const blocks = [
    {
      name: "Active users",
      content: "1600",
      icon: "user-1",
    },
    {
      name: "Global clients",
      content: "2500",
      icon: "destination-map-1",
    },
    {
      name: "Win award",
      content: "215",
      icon: "quality-1",
    },
    {
      name: "Client satisfaction",
      content: "2150",
      icon: "sales-up-1",
    },
  ]
  return (
    <section className="py-5">
      <Container className="pt-5">
        <Row>
          <Col lg="7" className="col-lg-7 mx-auto text-center">
            <p className="h6 text-uppercase text-primary">Testimonials</p>
            <h2>What People Says About Us</h2>
            <p className="text-muted mb-5">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt
            </p>
          </Col>
        </Row>
        <Row className="row align-items-center">
          <Col lg="6" className="mb-5 mb-lg-0">
            <Swiper
              className="testimonials-slider"
              pagination={{ clickable: true }}
              spaceBetween={20}
              autoplay
            >
              {slides.map((slide) => (
                <SwiperSlide key={slide.name}>
                  <blockquote className="blockquote border-0 p-0">
                    <p className="blockquote-text text-muted">
                      {slide.content}
                    </p>
                  </blockquote>
                  <footer className="blockquote-footer">
                    <div className="media align-items-center">
                      <Img
                        fixed={slide.img}
                        alt={slide.name}
                        className="rounded-circle shadow-sm"
                      />

                      <div className="media-body ml-3">
                        <h5 className="text-dark mb-0">{slide.name}</h5>
                        <cite className="text-gray">{slide.type}</cite>
                      </div>
                    </div>
                  </footer>
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
          <Col lg="5" className="ml-auto">
            <Row>
              {blocks.map((block) => (
                <Col xs="6" className="mb-4" key={block.name}>
                  <Card className="shadow">
                    <CardBody className="text-center">
                      <Icon icon={block.icon} className="text-primary mb-3" />
                      <h3 className="mb-1">{block.content}</h3>
                      <p className="text-muted mb-0">{block.name}</p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Testimonials
